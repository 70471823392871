<template>
    <div>
        <div class="main-wrap">
        <!-- navigation -->
        <!-- main content -->
        <div class="pl-0 main-content" v-bind:class="{'menu-active': isMenuActive}">
            <div class="middle-sidebar-header bg-white position-sticky">
                <router-link to="/"><h1 class="fredoka-font ls-3 fw-700 text-current font-xxl ml-5">Elingway <span class="d-block font-xsssss ls-1 text-grey-500 open-font ">Learning Any Language Differently</span></h1></router-link>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul class="navbar-nav nav-menu float-none text-center">
                                    <li class="nav-item dropdown"><router-link class="nav-link dropdown-toggle" data-toggle="dropdown" to="/browse/courses">Course</router-link>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">Browse Course</a>
                                            <a class="dropdown-item" href="#">My Course</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                <ul class="d-flex ml-auto right-menu-icon">
                    <li>
                        <a href="#"><span class="dot-count bg-warning"></span><i class="feather-bell font-xl text-current"></i>
                            <div class="menu-dropdown">
                                <h4 class="fw-700 font-xs mb-4">Notification</h4>
                                <div class="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                                    <img src="https://via.placeholder.com/50x50.png" alt="user" class="w40 position-absolute left-0 rounded-circle">
                                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Fariz Alamsi'ah <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min</span></h5>
                                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">I just wanna say something</h6>
                                </div>
                                <div class="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                                    <img src="https://via.placeholder.com/50x50.png" alt="user" class="w40 position-absolute left-0 rounded-circle">
                                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Goria Coast <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 2 min</span></h5>
                                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                                </div>

                                <div class="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                                    <img src="https://via.placeholder.com/50x50.png" alt="user" class="w40 position-absolute left-0 rounded-circle">
                                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Surfiya Zakir <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 1 min</span></h5>
                                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                                </div>
                                <div class="card bg-transparent-card w-100 border-0 pl-5">
                                    <img src="https://via.placeholder.com/50x50.png" alt="user" class="w40 position-absolute left-0 rounded-circle">
                                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Victor Exrixon <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 30 sec</span></h5>
                                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li><router-link to="/message"><span class="dot-count bg-warning"></span><i class="feather-message-square font-xl text-current"></i></router-link></li>
                    <li><router-link to="/user/profile"><img src="https://via.placeholder.com/50x50.png" :alt="user.name" class="w40 rounded-circle mt--1"></router-link></li>
                    <li></li>
                </ul>
            </div>
            <div class="middle-sidebar-bottom">
                <div class="middle-sidebar-left">
                    <slot/>
                </div>
                <right-sidebar :is-sidebar-active="isSidebarActive" @close-sidebar="isSidebarActive = false"/>
                <button class="btn bg-current btn-circle text-white btn-neutral sidebar-right btn-toggle-sidebar" @click="toggleSidebar()">
                    <i class="btn-toggle-sidebar" v-bind:class="{'ti-angle-right' : isSidebarActive === true, 'ti-angle-left' : isSidebarActive === false}"></i>  
                </button>
            </div>            
        </div>
        <!-- main content -->
        <app-footer @button-sidebar-clicked="toggleSidebar" :user="user"/>

        <div class="app-header-search">
            <form class="search-form">
                <div class="form-group searchbox mb-0 border-0 p-1">
                    <input type="text" class="form-control border-0" placeholder="Search...">
                    <i class="fas fa-search">
                        
                    </i>
                    <a href="#" class="ml-1 mt-1 d-inline-block close searchbox-close">
                        <i class="ti-close font-xs"></i>
                    </a>
                </div>
            </form>
        </div>

        </div> 
        
    </div>
</template>

<script>
import axios from 'axios'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import RightSidebar from '../../helpers/RightSidebar.vue'
import AppFooter from '../../helpers/AppFooter.vue'

export default ({
    name: 'CoreLayout',
    components: {
        RightSidebar,
        AppFooter
    },
    data(){
        return {
            user: {},
            isSidebarActive: false,
            isNavbarActive: false,
            isMenuActive: false,
            fullpath: this.$route.path,
            options: {
                rewind : true,
                perPage : 3,
                autoplay : true,
                arrows : false,
                pagination: false,
                gap: '1rem'
            },
        }
    },
    mounted() {
        axios.get(`${process.env.VUE_APP_URL_API}/user`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            this.user = res.data
            
        }).catch((err) => console.error(err))
    },
    methods: {
        onLogout() {
            axios.get(`${process.env.VUE_APP_URL_API}/logout`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                this.$router.push({name: 'Login'})
            }).catch((err) => console.error(err))
        },
        toggleSidebar(){
            this.isSidebarActive = !this.isSidebarActive
        },
        toggleNavbar(){
            this.isNavbarActive = !this.isNavbarActive
        },
        toggleMenu(){
            this.isMenuActive = !this.isMenuActive
        },
        isActiveMenu(path){
            var fullPath = this.fullpath
            if(fullPath.indexOf(path) !== -1){
                return true
            } else {
                return false
            }
        }
    }
})
</script>
